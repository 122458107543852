var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{staticClass:"custom-dialog fullscreen-dialog widget-popup",attrs:{"before-close":_vm.popupClose,"show-close":false,"visible":_vm.visible},on:{"update:visible":function($event){_vm.visible=$event},"open":_vm.popupOpened}},[(_vm.widget != null)?_c('div',{class:['c-modal', 'c-modal--fullscreen', _vm.loading ? 'is-loading' : '']},[_c('div',{staticClass:"c-modal__head"},[_c('div',{staticClass:"c-modal__title"},[_c('h1',[_vm._v(_vm._s(_vm.widget.title))])]),_c('div',{staticClass:"c-modal__actions"},[(_vm.showGraphTableSwitcher)?_c('div',{staticClass:"c-toggle"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.showGraph),expression:"showGraph"}],staticClass:"c-toggle__item c-toggle__item--left",attrs:{"id":"widget-popup-chart-btn","type":"radio"},domProps:{"value":true,"checked":_vm._q(_vm.showGraph,true)},on:{"change":function($event){_vm.showGraph=true}}}),_c('label',{attrs:{"for":"widget-popup-chart-btn"},on:{"click":function($event){_vm.showGraph = true}}},[_c('i',{staticClass:"material-icons"},[_vm._v("bar_chart")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.showGraph),expression:"showGraph"}],staticClass:"c-toggle__item c-toggle__item--right",attrs:{"id":"widget-popup-table-btn","type":"radio"},domProps:{"value":false,"checked":_vm._q(_vm.showGraph,false)},on:{"change":function($event){_vm.showGraph=false}}}),_c('label',{attrs:{"for":"widget-popup-table-btn"},on:{"click":function($event){_vm.showGraph = false}}},[_c('i',{staticClass:"material-icons"},[_vm._v("table_chart")])])]):_vm._e(),(_vm.debugInfos != null && _vm.debugInfos.length > 0)?_c('div',{staticClass:"c-toggle"},[_vm._l((_vm.tabs),function(tab,index){return [(index <= _vm.debugInfos.length)?_c('div',{key:index},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedTab),expression:"selectedTab"}],class:[
                                    'c-toggle__item',
                                    index === 0 ? 'c-toggle__item--left' : '',
                                    index > 0 && index === _vm.debugInfos.length
                                        ? 'c-toggle__item--right'
                                        : '',
                                ],attrs:{"id":'widget-popup-tab-' + tab.id,"type":"radio"},domProps:{"value":tab.id,"checked":_vm._q(_vm.selectedTab,tab.id)},on:{"change":function($event){_vm.selectedTab=tab.id}}}),_c('label',{attrs:{"for":'widget-popup-tab-' + tab.id}},[_c('i',{staticClass:"material-icons"},[_vm._v(_vm._s(tab.icon))])])]):_vm._e()]})],2):_vm._e(),(_vm.fullScreen)?_c('button',{staticClass:"c-btn c-btn--clean c-btn--gray",attrs:{"title":_vm.$t('RELOAD')},on:{"click":_vm.reloadWidget}},[_c('i',{staticClass:"material-icons"},[_vm._v("refresh")])]):_vm._e(),_c('button',{staticClass:"c-btn c-btn--clean c-btn--gray",on:{"click":_vm.popupClose}},[_c('i',{staticClass:"material-icons"},[_vm._v("close")])])])]),_c('div',{staticClass:"c-modal__content"},[(_vm.selectedTab === 'preview')?_c('div',{staticClass:"c-chart"},[_c('div',{staticClass:"c-chart__media"},[_c('widget-view',{attrs:{"chart-error-message":_vm.chartErrorMessage,"chart-options":_vm.chartOptions,"error-message":_vm.errorMessage,"preview":_vm.preview,"show-graph":_vm.showGraph,"table-data":_vm.tableData,"widget-data":_vm.widgetData,"x-axis-name":_vm.xAxisName}})],1)]):_vm._e(),_vm._l((_vm.debugInfos),function(debugInfo,index){return _c('div',{key:index},[(_vm.selectedTab === 'debug' + (index + 1))?_c('debug-info',{attrs:{"data":debugInfo,"order":index + 1}}):_vm._e()],1)}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],staticClass:"c-loader"})],2),_c('div',{staticClass:"c-modal__footer"})]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }