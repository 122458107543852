<template>
    <div class="widget-view">
        <div v-if="!errorMessage && widgetData != null">
            <div v-if="showGraph && widgetData.widgetType === 'SINGLE_VALUE'" class="singleValue">
                <span :style="{ color: singleValueColor() }" class="singleValueData">{{
                    formatSingleValue()
                }}</span>
            </div>
            <widget-chart
                v-if="
                    !chartErrorMessage &&
                    chartOptions != null &&
                    showGraph &&
                    widgetData.widgetType !== 'SINGLE_VALUE' &&
                    widgetData.widgetType !== 'TABLE'
                "
                ref="widgetChart"
                :chart-options="chartOptions"
            ></widget-chart>
            <widget-table
                v-if="!showGraph || widgetData.widgetType === 'TABLE'"
                :data="tableData"
                :preview="preview"
                :widget-id="widgetData.id"
                :xAxisName="xAxisName"
            ></widget-table>
            <div v-show="showTarget" class="showTarget">
                <span>{{ $t("TARGET") }}</span> - <span>{{ widgetData.target }}</span>
            </div>
        </div>
        <div v-if="errorMessage" class="widget-chart-error-message">{{ $t(errorMessage) }}</div>
        <div
            v-if="
                chartErrorMessage &&
                showGraph &&
                widgetData.widgetType !== 'SINGLE_VALUE' &&
                widgetData.widgetType !== 'TABLE'
            "
            class="widget-chart-error-message"
        >
            {{ $t(chartErrorMessage) }}
        </div>
        <div class="clearfix"></div>
    </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import util from "@/helpers/evaluation/util";
import WidgetChart from "./Chart.vue";
import WidgetTable from "./Table.vue";

export default {
    name: "WidgetView",
    mixins: [util, validationMixin],
    props: [
        "widgetData",
        "chartOptions",
        "errorMessage",
        "chartErrorMessage",
        "tableData",
        "xAxisName",
        "preview",
        "showGraph",
    ],
    components: {
        WidgetChart,
        WidgetTable,
    },
    computed: {
        showTarget() {
            return (
                this.showGraph &&
                !this.chartErrorMessage &&
                this.widgetData != null &&
                this.isValueNumeric(this.widgetData.target) &&
                (this.widgetData.widgetType === "SINGLE_VALUE" ||
                    this.widgetData.displayType === "pie")
            );
        },
    },
    methods: {
        formatSingleValue() {
            const item = this.widgetData.series[0];
            const plus = item.name === "NPS" && item.data[0][0] > 0 ? "+" : "";
            const percentage = item.name === "PERCENTAGE" ? "%" : "";
            return plus + item.data[0][0] + percentage;
        },
        singleValueColor() {
            let displayValue = this.formatSingleValue();
            const { target } = this.widgetData;
            if (this.widgetData.series[0].name === "PERCENTAGE") {
                displayValue = displayValue.replace("%", "");
            }
            if (this.isValueNumeric(target) && this.isValueNumeric(displayValue)) {
                if (target > displayValue) {
                    return "red";
                }
                return "limegreen";
            }
        },
    },
};
</script>
