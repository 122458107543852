<template>
    <div class="debug-info">
        <div class="debug-info-query">{{ $t("SANITIZED_QUERY") + " " + order }}</div>
        <p class="debug-info-content">{{ data.sanitizedQuery }}</p>
        <div class="debug-info-error">{{ $t("ERROR_MESSAGE") }}</div>
        <p class="debug-info-content">{{ data.errorMessage }}</p>
    </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import util from "@/helpers/evaluation/util";

export default {
    name: "DebugInfo",
    mixins: [util, validationMixin],
    props: ["data", "order"],
};
</script>
