<template>
    <div v-if="data != null" class="widget-table">
        <table class="table table-striped">
            <thead>
                <tr>
                    <th class="sortable" rowspan="2" @click="order(0)">
                        <span class="table-sort-text">{{ $t(xAxisName) }}</span>
                        <span v-show="sortColumn === 0" class="material-icons table-sort">{{
                            sortOrder ? "arrow_drop_down" : "arrow_drop_up"
                        }}</span>
                    </th>
                    <template v-for="(tableGroup, i) in data.tableGroups">
                        <th
                            v-if="data.tableGroups.length > 1 && tableGroup.display"
                            :colspan="tableGroup.colspanValue"
                            :key="i"
                        >
                            <center>
                                {{ tableGroup.groupName ? tableGroup.groupName : "Default" }}
                            </center>
                        </th>
                    </template>
                </tr>
                <tr>
                    <th
                        v-for="(series, index) in data.tableSeries"
                        class="sortable"
                        :key="index"
                        @click="order(index + 1)"
                    >
                        <span v-if="!series.numberOfRespondentsLabel" class="table-sort-text">{{
                            $t(series.label)
                        }}</span>
                        <span v-if="series.numberOfRespondentsLabel" class="table-sort-text">{{
                            $t("NUMBER_OF_RESPONDENTS")
                        }}</span>
                        <span v-show="sortColumn === index + 1" class="material-icons table-sort">{{
                            sortOrder ? "arrow_drop_down" : "arrow_drop_up"
                        }}</span>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(row, i) in filtered" :key="i">
                    <td v-for="(columnData, j) in row" :key="j">
                        <span v-if="columnData && columnData.displayValue">{{
                            columnData.displayValue
                        }}</span>
                        <span v-if="!columnData || !columnData.displayValue">{{ columnData }}</span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { widgetService } from "@/services/Evaluation";
import util from "@/helpers/evaluation/util";

export default {
    name: "WidgetTable",
    mixins: [util, validationMixin],
    props: ["widgetId", "data", "xAxisName", "preview"],
    data() {
        return {
            rows: [],
            filtered: [],
            sortColumn: 0,
            sortOrder: false,
            widget: null,
        };
    },
    mounted() {
        this.initialize();
    },
    methods: {
        initialize() {
            this.rows = this.data.tableSet;
            if (!this.preview && typeof this.widgetId === "number") {
                widgetService.getWidget(this.widgetId).then((widget) => {
                    this.widget = widget;
                    if (this.widget.sortColumn != null) {
                        this.sortColumn = this.widget.sortColumn;
                    }
                    if (this.widget.sortOrder != null) {
                        this.sortOrder = this.widget.sortOrder;
                    }
                    this.setFilteredList();
                });
            } else {
                this.setFilteredList();
            }
        },
        setFilteredList() {
            const filteredList = [];
            $.each(this.rows, (index, row) => {
                filteredList.push(row);
            });
            filteredList.sort(this.objectSortFunc(this.sortColumn));
            if (this.sortOrder) {
                filteredList.reverse();
            }
            this.filtered = filteredList;
        },
        order(index) {
            this.sortOrder = this.sortColumn === index ? !this.sortOrder : false;
            this.sortColumn = index;
            this.setFilteredList();

            if (!this.preview && typeof this.widgetId === "number") {
                this.widget.sortColumn = this.sortColumn;
                this.widget.sortOrder = this.sortOrder;
                widgetService.updateWidget(this.widget).then((widget) => {
                    this.widget = widget;
                });
            }
        },
    },
    watch: {
        // rerender table when filters change
        data() {
            this.initialize();
        },
    },
};
</script>
