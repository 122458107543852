<template>
    <div class="widget-chart">
        <vue-highcharts
            v-if="chartOptions != null"
            :key="renderCounter"
            ref="chart"
            :options="chartOptions"
            up
        >
        </vue-highcharts>
    </div>
</template>

<script>
import { Chart } from "highcharts-vue";
import Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";
import SolidGauge from "highcharts/modules/solid-gauge";
import Exporting from "highcharts/modules/exporting";
import HighchartsOfflineExporting from "highcharts/modules/offline-exporting";
import { validationMixin } from "vuelidate";
import util from "@/helpers/evaluation/util";
import { EventBus } from "@/helpers/evaluation/event-bus";

HighchartsMore(Highcharts);
SolidGauge(Highcharts);
Exporting(Highcharts);
HighchartsOfflineExporting(Highcharts);

export default {
    name: "WidgetChart",
    mixins: [util, validationMixin],
    props: ["chartOptions"],
    components: {
        VueHighcharts: Chart,
    },
    data() {
        return {
            renderCounter: 1,
        };
    },
    watch: {
        chartOptions() {
            // eslint-disable-next-line no-plusplus
            this.renderCounter++;
        },
    },
    methods: {
        redraw() {
            // eslint-disable-next-line no-plusplus
            this.renderCounter++;
        },
    },
    created() {
        EventBus.$on("redrawAllCharts", this.redraw);
    },
    beforeDestroy() {
        EventBus.$off("redrawAllCharts");
    },
};
</script>
