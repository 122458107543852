/* eslint-disable func-names */
import { EventBus } from "@/helpers/evaluation/event-bus";

export default function () {
    EventBus.$off("openWidgetEdit");
    EventBus.$off("openTextWidgetEdit");
    EventBus.$off("openWidgetPopup");
    EventBus.$off("deleteFilters");
    EventBus.$off("configurationChanged");
    EventBus.$off("expandColumn");
    EventBus.$off("openWidgetTypeSelection");
    EventBus.$off("addWidgetToColumn");
    EventBus.$off("addTextWidgetToColumn");
    EventBus.$off("removeWidgetFromColumn");
    EventBus.$off("addRow");
    EventBus.$off("moveRow");
    EventBus.$off("removeRow");
    EventBus.$off("reloadDashboardNavigation");
}
