<!-- eslint-disable vue/no-mutating-props -->
<template>
    <el-dialog
        :visible.sync="visible"
        v-on:opened="popupOpened"
        :before-close="popupClose"
        class="custom-dialog confirm-dialog"
        :show-close="false"
    >
        <div class="c-modal c-modal--with-tabs">
            <div class="c-modal__head">
                <div class="c-modal__title">
                    <h1>{{ $t(title) }}</h1>
                </div>
                <div class="c-modal__actions">
                    <button class="c-btn c-btn--clean c-btn--gray" @click="popupClose">
                        <i class="material-icons">close</i>
                    </button>
                </div>
            </div>
            <div class="c-modal__content">
                <div class="o-form">
                    <fieldset>
                        <ul class="o-form__fields o-list-plain">
                            <li
                                class="o-form__item o-form__item--sm o-form__item--stacked"
                                v-show="!validationStep"
                            >
                                {{ $t(message, messageParams) }}
                            </li>
                            <li
                                class="o-form__item o-form__item--sm o-form__item--stacked"
                                v-show="validationStep"
                            >
                                <input
                                    class="o-form__input o-form__input--full"
                                    type="text"
                                    ref="inputText"
                                    v-model="inputText"
                                    autocomplete="off"
                                />
                                <label class="o-form__label confirm-input-label">{{
                                    $t(inputMessage)
                                }}</label>
                            </li>
                        </ul>
                    </fieldset>
                </div>
            </div>
            <div class="c-modal__footer">
                <button class="c-btn c-btn--outline" @click="popupClose">
                    {{ $t(cancelBtn) }}
                </button>
                <button
                    class="c-btn c-btn--primary"
                    @click="dialogConfirmed"
                    :disabled="validationStep && !isTextValid()"
                >
                    {{ $t(confirmBtn) }}
                </button>
            </div>
        </div>
    </el-dialog>
</template>

<script setup>
import { ref } from "vue";

const props = defineProps([
    "visible",
    "title",
    "message",
    "messageParams",
    "inputMessage",
    "cancelBtn",
    "confirmBtn",
    "validationText",
    "callback",
]);

const emit = defineEmits(["close", "confirmed"]);

const validationStep = ref(false);
const inputText = ref("");

function popupOpened() {
    validationStep.value = false;
    inputText.value = "";
}
function popupClose() {
    emit("close");
    validationStep.value = false;
    inputText.value = "";
}
function isTextValid() {
    // eslint-disable-next-line no-undef
    return translate(props.validationText) === inputText.value;
}
function dialogConfirmed() {
    if (props.validationText == null || props.validationText === "" || isTextValid()) {
        emit("confirmed");
    } else if (!validationStep.value) {
        validationStep.value = true;
        inputText.value.focus();
    }
}
</script>

<style scoped>
.confirm-input-label {
    font-size: 14px;
    text-transform: none;
}
</style>
